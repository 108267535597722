@use 'styles/theme/theme' as theme;

.monthDay {
  height: 30px;
  width: 16.5px;
  background: theme.$grey18;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.monthDayWeekEnd {
  background: theme.$archivedBackgroundGrey;
}

.monthDayFuture {
  background: theme.$orange;
  span {
    color: theme.$white;
  }
}

.monthDayPast {
  background: theme.$orange5;
  span {
    color: theme.$primaryGrey;
  }
}

.fifteenDay {
  width: 35px;
  height: 26px;
  span {
    color: theme.$orange;
  }
}

.fifteenDayPast {
  span {
    color: theme.$orangeLight4;
  }
}

@media screen and (max-width: 1200px) {
  .monthDay {
    height: 62px;
  }
}