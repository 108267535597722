@use 'styles/theme/theme' as theme;


.calendarSlug {
  display: flex;
  line-height: 1;
  position: relative;
  align-items: center;
  &:hover {
    .divider {
      background-color: theme.$blue;
      width: 2px;
    }
  }
  .divider {
    content: '';
    position: absolute;
    left: -1px;
    height: 100%;
    width: 1px;
    background-color: theme.$borderGrey3;
    height: 30px;
  }
  &__leftBlock {
    display: flex;
    align-items: center;
    gap: 12px;
    &_genre {
      width: 71px;
      padding: 7px 4px 7px 8px;
    }
    &_prodName {
      width: 181px;
      padding: 6px 4px 6px 8px;
    }
    &_producer {
      width: 113px;
      padding: 6px 4px 2px 8px;
      @include theme.ellipsis($lineClamp: 2);
    }
    &_conductorDirector {
      width: 126px;
      .directors, .conductors {
        display: flex;
      }
    }
    &_countryCity {
      width: 107px;
    }
    &_ticket {
      width: 66px;
    }

    .tabletProducer, .tabletTicketAction {
      display: none;
    }
  }
}

@media screen and (max-width: 1280px) {
  .calendarSlug {
    &__leftBlock {
      gap: 6px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .calendarSlug {
    margin-bottom: 12px;
    .divider {
      height: 90%;
    }
    &__leftBlock {
      align-items: flex-start;
      &_genre {
        width: 67px;
        padding: 3px 4px;
      }
      &_prodName {
        padding: 3px 4px;
        width: 136px;
      }
      &_producer {
        display: none;
      }
      &_conductorDirector {
        width: 101px;
      }
      &_ticket {
        display: none;
      }
      .tabletTicketAction {
        display: block;
        margin-left: 10px;
      }
      .tabletProducer {
        display: block;
        margin-top: 3px;
      }
    } 
  }
}

.workTag {
  background: transparent;
  color: theme.$primaryGrey;
  font-size: 9px;
  font-weight: theme.$font-weight-bold;
  text-transform: uppercase;
  border-radius: 1px;
  border: 0.75px solid theme.$tertiaryGrey;
  border-radius: 0;
  padding: 5px 6px;
  line-height: 1;
}


.months {
  padding-left: 0.25rem;
  padding-right: 0.25rem; 
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 
  border-left-width: 1px; 
  border-color: theme.$borderGrey3; 
}

.productionName {
  &__name, &__workName {
    font-size: 12px;
    @include theme.ellipsis($lineClamp: 2);
  }

  &__workName {
    span {
      font-size: 12px;
    }
  }
}

.ticketsBtn {
  padding: 7px 3px;
  border-radius: 0;
  font-size: 12px;
  &:hover {
    cursor: pointer;
    background-color: theme.$blue;
    color: theme.$white;
    text-decoration: none;
    .ticketsBtnIcon {
      fill: theme.$white !important;
    }
  }
}

.venues {
  &__location {
    display: flex;
    flex-direction: column;
  }
}

.locationSingle {
  display: flex;
  .expandIcon {
    width: 14px;
    svg {
      margin-top: -3px;
    }
  }
}