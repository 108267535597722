@use 'styles/theme/theme' as theme;

.calendarRangeNavigation {
  display: flex;
  flex-direction: column;
}

.showTypes {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 3.5px;
  &__type {
    display: flex;
    align-items: center;
    gap: 4px;
    &_box {
      width: 6px;
      height: 6px;
    }
    .box_past {
      background-color: theme.$orangeLight4;
    }
    .box_upcoming {
      background-color: theme.$orange5;
    }
    .box_weekend {
      background-color: theme.$archivedBackgroundGrey;
    }
    .box_cancelled {
      background-color: theme.$red-error;
    }
  }
}

.yearNavigation {
  min-width: 552px;
  border-radius: 4px;
  border: 1px solid theme.$borderGrey;
  text-align: center;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    margin: 2px 8px 0 8px;
  }

  &__previous, &__next {
    background: transparent !important;
    border: none !important;
    width: 30px !important;
    height: 30px !important;
    &:hover {
      box-shadow: none !important;
    }
  }
}


@media screen and (max-width: 1200px) {
  .calendarRangeNavigation {
    width: 100%;
  }
}