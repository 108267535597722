@use 'styles/theme/theme' as theme;

.slugTable {
  border-collapse: collapse;
  &__head {
    &_row {
      height: 34px;
    }
    &_filters {
      align-content: flex-end;
    }
  }

  &__row {
    &:hover {
      border-radius: 4px;
      background: theme.$white;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    }
  }
}

.calendarProductionNavigation {
  display: flex;
  justify-content: space-between;
}

.filterDateSelect {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.filterContainerDetailed {
  align-self: flex-end;
  width: max-content;
  .filterGroupBox:nth-child(1) {
    width: 71px;
    min-width: 71px;
  }
  .filterGroupBox:nth-child(2) {
    width: 181px;
    min-width: 181px;
  }
  .filterGroupBox:nth-child(3) {
    width: 113px;
    min-width: 113px;
  }
  .filterGroupBox:nth-child(4) {
    width: 126px;
    min-width: 126px;
  }
  .filterGroupBox:nth-child(5) {
    width: 107px;
    min-width: 107px;
  }
  .filterGroupBox:last-child {
    width: 65px;
    min-width: 65px;
    flex: 0;
  }
}


@media screen and (max-width: 1280px) {
  .calendarProductionNavigation {
    &:last-child {
      flex: 1;
    }
  }
  .filterContainerDetailed {
    gap: 6px;
  }
}
