@use 'styles/theme/theme' as theme;

.venue {
  &__location {
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__name {
    display: flex;
    gap: 2px;
    align-items: center;
  }
}

.country {
  color: theme.$blackGrey5;
}

.moreVenues {
  margin-top: 4px;
  text-transform: lowercase;
}
