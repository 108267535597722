@use 'styles/theme/theme' as theme;

.months {
  padding: 6px;
  border-left-width: 1px; 
  border-color: theme.$borderGrey3;
  text-align: center;
  border-right: 1px solid theme.$gray6;
  &:last-child {
    border-right: none;
  }
}
