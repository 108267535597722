@use 'styles/theme/theme' as theme;

.performanceDates {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.groupedDates {
  display: inline;
  white-space: nowrap;
  &__month {
    display: inline;
    margin-right: 3px;
    &_name {
      display: inline;
      white-space: nowrap;
      margin-right: 2px;
    }
    &_dates {
      display: inline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
      flex-shrink: 1;
    }
  }
}

.datesList {
  display: inline;
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    display: inline;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
}
