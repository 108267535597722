@use 'styles/theme/theme' as theme;

.actionsWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  .actionBtn, .actionBtnSecondary {
    padding: 8px;
    border-radius: 2px;
    width: 144px;
    height: 30px;
    min-width: 144px;
    font-size: theme.$font-size-12;
  }

  .actionBtnSecondary {
    background: transparent;
    border: 1px solid theme.$blue;
  }
  
  .priceRange {
    font-size: theme.$font-size-11;
    font-weight: theme.$font-weight-medium;
    color: theme.$white;
    margin-left: 4px;
  }
}

.ticketPrice {
  color: theme.$white;
  margin-left: 4px;
}

.actions {
  display: flex;
  gap: 8px;

  &__secondary {
    display: flex;
    gap: 8px;
    &_bookmark {
      line-height: 1;
    }
  }
}

.icon {
  fill: theme.$primaryGrey !important;
}

.share {
  span {
    background: transparent;
    border: 0px;
    padding: 0px !important;
    margin-top: 3px;
    &:hover {
      background: transparent;
    }
  }
  svg {
    height: 20px !important;
    width: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .actions {
    gap: 22px;
    &__primary {
      width: 222px;
    }
    &__secondary {
      gap: 24px;
      align-items: center;
    }

    .share {
      span {
        margin-top: 0;
      }
    }
  }
  .actionBtn, .actionBtnSecondary {
    width: 222px !important;
    min-width: 222px !important;
  }
}