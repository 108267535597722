@use 'styles/theme/theme' as theme;

.month {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  gap: 2px;
}

.monthsBar {
  display: grid;
  grid-template-columns: repeat(31, minmax(0, 1fr));
  gap: 2px;
}

.dayCircle {
  width: 4px;
  height: 4px;
  background: theme.$grey18;
  border-radius: 50%;
}

.dayBar {
  width: 4px;
  height: 30px;
  background: theme.$grey18;
  margin-top: 2px;
  margin-right: 2px;
}

.dayCircleWeekEnd, .dayBarWeekEnd {
  background: theme.$archivedBackgroundGrey;
}

.dayBarWeekEnd {
  position: relative;
  &::before {
    position: absolute;
    content: "";
    height: 4px;
    width: 4px;
    background: theme.$archivedBackgroundGrey;
    top: -5px;
    left: 0;
  }
}

.dayCircleFuture, .dayBarFuture {
  background: theme.$orange;
}

.dayCirclePast, .dayBarPast {
  background: theme.$orangeLight4;
}


@media screen and (max-width: 1200px) {
  .dayBar {
    height: 70px;
  }
}