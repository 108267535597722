$base-font-family: 'Roboto', sans-serif;
$secondary-font-family: 'Playfair Display', serif;

$font-size-8: 8px;
$font-size-9: 9px;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-40: 40px;
$font-size-50: 50px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-upper-medium: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 900;

$font-style-normal: normal;
$font-style-italic: italic;

$line-height-heading: 1.172;
$line-height-14: 14px;
$line-height-16: 16px;
$line-height-17: 17px;
$line-height-19: 19px;
$line-height-20: 20px;
$line-height-21: 21px;
$line-height-22: 22px;
$line-height-24: 24px;
$line-height-26: 26px;
$line-height-28: 28px;
$line-height-30: 30px;

@mixin typography($size, $weight: $font-weight-regular, $lineHeight: $line-height-heading, $style: $font-style-normal, $color: $primaryGrey) {
  font-size: $size;
  font-family: $base-font-family;
  font-weight: $weight;
  font-style: $style;
  @if $color != none {
    color: $color;
  }
  line-height: $lineHeight;
  margin: 0;
}

@mixin blurText {
  filter: blur(3px);
  color: $secondaryGrey;
}

@mixin ellipsis($lineClamp) {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
}

@mixin visuallyHidden {
  display: none;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
