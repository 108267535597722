@use 'styles/theme/theme' as theme;

.productionSlugDesktop {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  &__container {
    display: flex;
    flex-direction: row;
    gap: 6px;
  }
  
  .column1 {
    width: 80px;
    min-width: 80px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding-right: 6px;
    &_otherTags, &_actions {
      display: none;
    }
  }

  .column2 {
    width: 134px;
    min-width: 134px;
    padding-right: 6px;
    &_datesAndVenues {
      display: none;
    }
  }
  
  .column3 {
    width: 120px;
    min-width: 120px;
    &_worksProducer, &_venues {
      display: none;
    }
  }
  
  .column4 {
    width: 140px;
    min-width: 140px;
    padding-right: 6px;
    line-height: 1;
    &_crewCastInst {
      display: none;
    }
  }
  
  .column5 {
    width: 200px;
    min-width: 200px;
    padding-right: 6px;
    &_actions {
      display: none;
    }
  }
  
  .column6 {
    width: 98px;
    min-width: 98px;
    padding-right: 6px;
  }
  
  .column7 {
    width: 108px;
    min-width: 108px;
    padding-right: 6px;
  }
  
  .column8 {
    width: 146px;
    min-width: 146px;
    padding-right: 6px;
  }
  
  .column9 {
    width: 200px;
    min-width: 200px;
    padding-right: 6px;
  }
}

.workStageTagBlock {
  display: flex;
  gap: 7px;
}

.mainTags {
  line-height: 1;
}

.expandIconItem {
  display: block;
  line-height: 1;

  svg {
    fill: theme.$tertiaryGrey !important;
  }
}

.workTag {
  background: transparent;
  border: 0px;
  border-radius: 0;
  padding: 0;
  font-size: theme.$font-size-12;
  font-weight: theme.$font-weight-medium;
  text-transform: uppercase;
  max-width: max-content;
  color: theme.$primaryGrey;
  line-height: normal;
}

.stageTag {
  margin-top: 3px;
  margin-right: 2px;
}

.tagList {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.productionTags {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.otherTag {
  border: 1px solid theme.$secondaryGrey;
  padding: 4px;
  text-transform: uppercase;
  height: 20px;
  display: inline-block;
  width: max-content;
  
  &:last-child {
    margin-right: 0;
  }
}

.dateRange {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  
  .rangeDates {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .dateYear {
    display: inline-flex;
    align-items: center;
  }
}

.producerLink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;

  span {
    color: theme.$blackGrey5;
  }
  
  &:hover {
    text-decoration: none;
  }
}

.seasonPeriod {
  color: theme.$secondaryGrey;
  margin-top: 2px;
}

.cdBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  
  &:last-child {
    margin-bottom: 0;
  }
  
  &__namesList {
    display: flex;
    flex-direction: column;
  }
  
  &__nameItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2px;
    span {
      white-space: break-spaces;
      color: theme.$blackGrey5;
    }
    &:not(:first-child) {
      margin-left: 16px;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__prefix {
    margin-right: 4px;
    color: theme.$blackGrey5;
  }
}

  
.castCrewName {
  text-decoration: none;

  span {
    color: theme.$primaryGrey2;
  }
  
  &:hover {
    text-decoration: underline;
  }
}

.castInstrumentalist {
  display: flex;
  flex-direction: column;
  
  &__namesList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2px;
  }
  
  &__nameItem {
    display: flex;
    align-items: center;
  }
  
  &__separator {
    margin-right: 4px;
  }
}

.musicalWorks {
  display: flex;
  flex-direction: column;
  
  .workTitle {
    display: inline;
  }
  
  .composers {
    margin-top: 2px;
  }
  
  .programCount {
    margin-top: 4px;
  }
  
  .multipleWorks {
    display: flex;
    flex-direction: column;
  }
}

.firstPerformanceInfo {
  display: flex;
  gap: 2px;
  margin-bottom: 2px;
}


.productionSlugMobile {
  width: 100%;
  list-style: none;
  padding: 20px 0px;
  border-bottom: 1px solid theme.$primaryGrey;

  &:first-child {
    border-top: 1px solid theme.$primaryGrey;
  }

  &__container {
    width: 100%;
    .mobileBody {
      display: flex;
      gap: 17px;
      .mobileColumn1 {
        width: 130px;
      }
      .mobileColumn2 {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }
    .mobileFooter {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__tags {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
  }
}


@media (max-width: 1280px) {
  .productionSlugDesktop__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .column1, .column2, .column3, .column4, .column5 {
      flex: 1;
      min-width: auto;
      padding-right: 6px;
    }

    .column6, .column7, .column8, .column9 {
      display: none;
    }

    .column1 {
      &_otherTags {
        display: block;
        margin-left: 22px;
      }
    }
    .column2 {
      &_tags {
        display: none;
      }
      &_datesAndVenues {
        display: block;
      }
    }
    .column3 {
      width: 200px;
      min-width: 200px;
      &_dates {
        display: none;
      }
      &_worksProducer {
        display: block;
      }
    }
    .column4 {
      &_venuesListing {
        display: none;
      }
      &_crewCastInst {
        display: block;
      }
    }
    .column5 {
      &_works {
        display: none;
      }
      &_actions {
        display: block;
      }
    }
  }
}

@media (max-width: 860px) {
  .column1 {
    flex: 2 !important;
    &_actions {
      margin-top: 4px;
      display: block !important;
      margin-left: 22px;
    }
  }
  .column5 {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .producerLink {
    gap: 4px;
    align-items: center;
    flex-direction: row;
  }
}

