@use 'styles/theme/theme' as theme;

.root {
  margin: 0px auto 0px auto;
  max-width: 1280px;
  width: 100%;
}

.mobileRoot {
  padding: 0px;
}

.filtersFacetsWrapper, .appliedFiltersWrapper {
  @media screen and (max-width: 600px) {
    margin-left: 16px;
  }
}

.appliedFilters {
  margin: 8px 0px 10px 0px;
}

.listWrapper {
 display: flex;
 flex-wrap: wrap;
 gap: 16px;
 margin: 0;
 padding: 0;
 margin-top: 16px;
 &__media {
  gap: 8px;
 }
 &__horizontal {
  flex-wrap: nowrap;
  gap: 8px;
 }
 &__listingPage {
  margin-top: 16px;
  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
 }
}

.filterContainerDetailed {
  margin-bottom: 20px;
  .filterGroupBox:nth-child(1) {
    width: 87px;
    min-width: 87px;
  }
  .filterGroupBox:nth-child(2) {
    width: 227px;
    min-width: 227px;
  }
  .filterGroupBox:nth-child(3) {
    width: 250px;
    min-width: 250px;
  }
  .filterGroupBox:nth-child(4) {
    width: 228px;
    min-width: 228px;
  }
  .filterGroupBox:nth-child(5) {
    width: 248px;
    min-width: 248px;
  }
  .filterGroupBox:last-child {
    flex: 1;
  }
}

.actions {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  &__primary {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &__viewTypeContainer {
    display: flex;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid theme.$borderGrey;
    .viewType {
      height: 34px;
      padding: 8px 12px !important;
      font-size: 12px;
      font-weight: 400;
      min-width: unset;
      border-radius: 4px;
      border: 1px solid transparent;
      color: theme.$secondaryGrey !important;
      
      &:hover, &:active, &__active {
        background-color: theme.$backgroundGrey3 !important;
        color: theme.$primaryGrey !important;
        box-shadow: none !important;
        border: 1px solid theme.$primaryGrey !important;
        svg {
          fill: theme.$primaryGrey !important;
        }
      }
    }
  }
}

.productionCount {
  margin-right: 4px;
}

@media screen and (max-width: 1280px) {
  .filterContainerDetailed {
    margin-bottom: 20px;
    .filterGroupBox:nth-child(1) {
      width: 87px;
      min-width: 87px;
    }
    .filterGroupBox:nth-child(2) {
      width: 227px;
      min-width: 227px;
    }
    .filterGroupBox:nth-child(3) {
      width: 250px;
      min-width: 250px;
    }
    .filterGroupBox:nth-child(4) {
      flex: 1;
    }
    .filterGroupBox:last-child {
      width: 168px;
      min-width: 168px;
      flex: 0;
    }
  }
}

@media screen and (max-width: 1050px) {
  .filterContainerDetailed {
    .filterGroupBox:nth-child(1) {
      width: 78px;
      min-width: 78px;
    }
    .filterGroupBox:nth-child(3) {
      width: 238px;
      min-width: 238px;
    }
    .filterGroupBox:nth-child(4) {
      flex: 1;
      width: 204px;
      min-width: 204px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .filterContainerDetailed {
    .filterGroupBox:nth-child(1) {
      width: 78px;
      min-width: 78px;
    }
    .filterGroupBox:nth-child(3) {
      flex: 1;
    }
  }
}

@media screen and (max-width: 800px) {
  .filterContainerDetailed {
    .filterGroupBox:nth-child(2) {
      width: 200px;
      min-width: 200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .filterContainerDetailed {
    .filterGroupBox:nth-child(1) {
      width: 78px;
      min-width: 78px;
    }
    .filterGroupBox:nth-child(2) {
      flex: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .actions { 
    flex-wrap: wrap;
  }

  .listWrapper {
    gap: 30px;
  }
}
