@use 'styles/theme/theme' as theme;

.yearMonthsHeader {
  border-left-width: 1px; 
  border-color: theme.$borderGrey3;
  text-align: center;
  border-right: 1px solid theme.$gray6;
  &:last-child {
    border-right: none;
  }
}

.yearMonthsHeader {
  padding: 6px;
  border-bottom: 1px solid theme.$gray6;
  padding-bottom: 8px;
  font-size: theme.$font-size-10;
  font-weight: theme.$font-weight-medium;
}

.dayHeader {
  &__day {
    margin-top: 5px;
  }
}

.quarterMonthHeader {
  text-align: left;
  padding: 8px;
}